import React from "react";
import { Link } from "react-router-dom";
import sectionData from "./../../data/section.json";

const Footer = () => {
  let data = sectionData["sectionData"].footer;
  return (
    <>
      <footer className="pt-80" style={{ background: "#cccccc" }}>
        <div className="subscription">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="wanna-contact"
                  style={{ marginBottom: 0, borderWidth: 0, paddingBottom: 0 }}
                >
                  <h2 style={{ color: "#ffffff" }}>{data.footerTop.title}</h2>
                  <div
                    className="hero-action wow fadeInUp"
                    data-wow-delay="0.8s"
                    data-wow-duration="1.5s"
                  >
                    <Link to="/contact" className="btn">
                      Kontakt
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-bottom-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="copy-right">
                        <p>
                          Copyright {data.footerBottom.copyRightYear} <br />
                          Wszystkie prawa zastrzeżone: Grupa
                          <a href={data.footerBottom.copyRightLink}>
                            {" "}
                            {data.footerBottom.copyRightFrom}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
