import React, { Component } from "react";
import sectionData from "./../../data/section.json";
import axios from "axios";

export default class Contact extends Component {
  render() {
    const data = sectionData["sectionData"]["contact"];
    return (
      <div
        className="contact-information wow fadeInUp"
        data-wow-delay="0.2s"
        data-wow-duration="1.5s"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-2"></div>
            <div className="col-12 col-2xl-8">
              <div className="information-inner">
                <div className="row">
                  <div className="col-sm-1"></div>
                  <div className="col-sm-11">
                    <div className="address-inner">
                      <div className="headre">
                        <h2>{data.getAdvice.title}</h2>
                      </div>

                      <div className="address-information">
                        <ul className="row">
                          {data.getAdvice.address.map((address, i) => {
                            return (
                              <li
                                className="media col-12"
                                key={i}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="icon"
                                  style={{
                                    marginRight: "30px",
                                  }}
                                >
                                  <i className={address.icon}></i>
                                </span>
                                <div className="media-body">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h4 style={{ marginBottom: "2px" }}>
                                      {address.title}
                                    </h4>
                                  </div>
                                  {address?.contentOne &&
                                    (address?.optionStrong ? (
                                      <p>
                                        <strong>{address?.contentOne}</strong>
                                      </p>
                                    ) : (
                                      <p>{address?.contentOne}</p>
                                    ))}
                                  {address?.contentTwo &&
                                    (address?.optionStrong ? (
                                      <p>
                                        <strong>{address?.contentTwo}</strong>
                                      </p>
                                    ) : (
                                      <p>{address?.contentTwo}</p>
                                    ))}
                                  {address?.contentTwo === "" && (
                                    <>
                                      <br />
                                    </>
                                  )}
                                  {address?.contentThree &&
                                    (address?.optionStrong ? (
                                      <p>
                                        <strong>{address?.contentThree}</strong>
                                      </p>
                                    ) : (
                                      <p>{address?.contentThree}</p>
                                    ))}
                                  {address?.contentFour && (
                                    <p>{address?.contentFour}</p>
                                  )}
                                  {address?.content5 && (
                                    <p>{address?.content5}</p>
                                  )}
                                  {address?.content6 && (
                                    <p>{address?.content6}</p>
                                  )}
                                  {address?.content7 === "" && (
                                    <>
                                      <br />
                                    </>
                                  )}
                                  {address?.content7 && (
                                    <p>{address?.content7}</p>
                                  )}
                                  {address?.content8 &&
                                    (address?.optionStrong ? (
                                      <p>
                                        <strong>{address?.content8}</strong>
                                      </p>
                                    ) : (
                                      <p>{address?.content8}</p>
                                    ))}
                                  {address?.content9 && (
                                    <p>{address?.content9}</p>
                                  )}{" "}
                                  {address?.content10 && (
                                    <p>{address?.content10}</p>
                                  )}{" "}
                                  {address?.content11 && (
                                    <p>{address?.content11}</p>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
