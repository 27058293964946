import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ErrorPage from "../../page/Error";
import Home from "../../page/Home";
import About from "./../../page/About";
import ContactPage from "./../../page/Contact";
import Service from "./../../page/Service";

function AppRoute() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/services" exact component={Service} />

        <Route path="/contact" exact component={ContactPage} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
}
export default AppRoute;
