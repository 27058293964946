import { React } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../global-components/SectionTitle";
import sectionData from "./../../data/section.json";

const Service = () => {
  let data = sectionData["sectionData"].service;
  return (
    <>
      {/* <!--===================== service =====================--> */}

      <section className="service pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <SectionTitle
                title={data.serviceSectionHeading.title}
                titleContent={data.serviceSectionHeading.content}
              />
            </div>
          </div>
          <div className="row no-gutters">
            {data.singleService.map((service, i) => {
              return (
                <div className="col-md-6 col-lg-4" key={i}>
                  {/* <!--===================== single service =====================--> */}
                  <div
                    className={`single-service text-center wow ${service.animation}`}
                    data-wow-delay="0.3s"
                    data-wow-duration="1.5s"
                  >
                    <div>
                      {/* <i className={service.icon}></i> */}
                      <img style={{ height: "80px" }} src={service.icon} />
                      <h4 style={{ marginBottom: "0px", minHeight: "34px" }}>
                        {service.title1}
                      </h4>
                      <h4 style={{ marginTop: "0px", minHeight: "34px" }}>
                        {service.title2}
                      </h4>
                      <p style={{ minHeight: "11.5rem" }}>{service.content}</p>
                    </div>
                  </div>
                  {/* <!--===================== End of single service =====================--> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* <!--===================== End of service =====================--> */}
    </>
  );
};
export default Service;
