import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import sectionData from "./../../data/section.json";

class Counter extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };

  render() {
    let data = sectionData["sectionData"].counter;
    return (
      <section
        className="counter mb-120"
        style={{ background: `url(${data.background})`, textAlign: "center" }}
      >
        <div className="container-fluid">
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {data.singleCounter.map((singleCount, i) => {
              return (
                <div
                  className="col-12 col-lg-4 mt-8 py-16"
                  key={i}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="single-counter"
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: "50px 10px 10px 10px", // Dodano niewielkie marginesy po bokach dla lepszego oddzielenia na małych ekranach
                    }}
                  >
                    <div
                      className="counter-content mt-6"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: "20px", // Dodano margines na dole dla lepszego oddzielenia na małych ekranach
                      }}
                    >
                      <VisibilitySensor
                        onChange={this.onVisibilityChange}
                        offset={{ top: 10 }}
                        delayedCall
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CountUp
                            style={{ fontSize: "54px", textAlign: "center" }}
                            separator={","}
                            end={
                              this.state.didViewCountUp ? singleCount.value : 0
                            }
                          />
                          <span style={{ fontSize: "54px", marginLeft: "5px" }}>
                            + {singleCount?.valueSuffix}
                          </span>
                        </div>
                      </VisibilitySensor>
                      <h3 style={{ textAlign: "center" }}>
                        {singleCount.title1}
                      </h3>
                      <h3 style={{ textAlign: "center", marginTop: "0" }}>
                        {singleCount.title2}
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}
export default Counter;
