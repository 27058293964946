import React from "react";
import sectionData from "../../data/section.json";
import Map from "../global-components/Map";
import Contact from "./Contact";

const MapContactWrapperV2 = () => {
  let data = sectionData["sectionData"].mapDescription;
  return (
    <>
      <section className="contact contact-page pb-120">
        <Contact />

        {/* <div className="contact-map">
          <Map
            let={data.let}
            lng={data.lng}
            zoom={data.zoom}
            mapLetLng={data.mapLetLng.center}
          />
        </div> */}
      </section>
    </>
  );
};

export default MapContactWrapperV2;
