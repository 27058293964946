import React, { useEffect } from "react";
import Footer from "../components/global-components/Footer";
import Service from "../components/section-components/Service";
import ScrollTop from "./../components/global-components/BackToTop";
import Layouts from "./../components/global-components/Layouts";
import NavigationMenu from "../components/global-components/NavigationMenu";
import PageTitle from "./../components/global-components/PageTitle";
import sectionData from "./../data/section.json";

const ServicePage = () => {
  let data = sectionData["sectionData"].sectionTitle.service;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layouts pageTitle="Service Page">
      <NavigationMenu />
      <PageTitle
        pageTitle={data.title}
        background={data.background}
        pageName={data.pageName}
      />
      <Service />
      <Footer />
      <ScrollTop />
    </Layouts>
  );
};
export default ServicePage;
