import { React } from "react";
import { Link } from "react-router-dom";
import sectionData from "./../../data/section.json";
import { makeFirstLetterPrimary } from "../../utils/styling";
function Hero(props) {
  let data = sectionData["sectionData"].banner;

  return (
    <>
      {/* <!--=================== hero area ===================--> */}

      <section
        className="hero-area"
        style={{ background: `url(${data.background})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              {/* <!--=================== hero inner area ===================--> */}

              <div className="hero-inner-content">
                <span
                  className="wow fadeInUp"
                  data-wow-delay="0.2s"
                  data-wow-duration="1.5s"
                >
                  {data.subtitle}
                </span>
                <h1
                  className="wow fadeInUp"
                  data-wow-delay="0.4s"
                  data-wow-duration="1.5s"
                  // style={{ fontSize: "5rem" }}
                >
                  {makeFirstLetterPrimary(data.title)}
                </h1>
                <p
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                  data-wow-duration="1.5s"
                >
                  {data.content1} <br />
                  {data.content2}
                </p>
                <div
                  className="hero-action wow fadeInUp"
                  data-wow-delay="0.8s"
                  data-wow-duration="1.5s"
                >
                  <Link to="/contact" className="btn">
                    Kontakt
                  </Link>
                </div>
              </div>

              {/* <!--=================== End of hero inner area ===================--> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!--===================== End of hero area =====================--> */}
    </>
  );
}

export default Hero;
