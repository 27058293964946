import React from "react";
import { Link } from "react-router-dom";
import sectionData from "./../../data/section.json";

const Companies = () => {
  const data = sectionData["sectionData"].companies;

  return (
    <section className="companies pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div
              className="section-title text-center mb-20 wow fadeInUp"
              data-wow-delay="0.1s"
              data-wow-duration="1.5s"
            >
              <span>{data.title}</span>
              <h2>{data.content}</h2>
            </div>
          </div>
        </div>

        {data.rows.map((row, rowIndex) => {
          // Jeśli center = true, ustawiamy justify-content-center
          const rowClass = row.center ? "row justify-content-center" : "row";
          return (
            <div
              className={rowClass}
              key={rowIndex}
              style={{ marginBottom: "30px" }}
            >
              {row.companyIndexes.map((companyIndex, i) => {
                const company = data.list[companyIndex];
                return (
                  <div className="col" key={i}>
                    <div
                      className="single-company-card wow fadeInUp"
                      data-wow-delay={`${0.1 * (companyIndex + 1)}s`}
                      data-wow-duration="1.5s"
                      style={{
                        background: "#fff",
                        border: "1px solid #eee",
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <div
                        className="company-logo"
                        style={{ textAlign: "center" }}
                      >
                        <img
                          src={company.logo}
                          alt={company.name}
                          style={{ maxHeight: "160px" }}
                        />
                      </div>
                      {/* <h4 style={{ textAlign: "center" }}>{company.name}</h4> */}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Companies;
