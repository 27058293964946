import { React } from "react";
import { makeFirstLetterPrimary } from "../../utils/styling";

const Layouts = (props) => {
  return (
    <>
      <title> {props.pageTitle} </title>
      <div>{props.children}</div>
    </>
  );
};
export default Layouts;
