import { React } from "react";
import { Link } from "react-router-dom";
import sectionData from "./../../data/section.json";

function Feature(props) {
  let data = sectionData["sectionData"].feature;

  return (
    <>
      <section className={`feature-area pb-90 video-modal-close`}>
        <div className="container">
          <div className="row justify-content-center">
            {data.map((feature, i) => (
              <div className="col-lg-4 col-md-6" key={i}>
                <div
                  className="single-feature wow fadeInUp"
                  data-wow-delay="0.3s"
                  data-wow-duration="1.5s"
                >
                  {/* <span className="feature-bg">
                    <i className={feature.icon}></i>
                  </span> */}
                  <img style={{ height: "80px" }} src={feature.icon} />
                  <h4>{feature.title}</h4>
                  <p>{feature.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
export default Feature;
