import React from "react";
import { Link } from "react-router-dom";

function ErrorContent() {
  return (
    <div>
      <section className="pb-120">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="error-content text-center">
                <h1>404</h1>
                <h3>Strona nie istnieje</h3>
                <p>
                  Wygląda na to, że podstrona której szukasz nie jest dostępna
                </p>
                <Link to="/" className="btn">
                  Powrót do strony głównej
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ErrorContent;
