import React, { useEffect } from "react";
import NavigationMenu from "../components/global-components/NavigationMenu";
import PageTitle from "../components/global-components/PageTitle";

import ScrollTop from "./../components/global-components/BackToTop";
import Footer from "./../components/global-components/Footer";
import Layouts from "./../components/global-components/Layouts";
import About from "./../components/section-components/About";
import sectionData from "./../data/section.json";

const AboutPage = () => {
  let data = sectionData["sectionData"].sectionTitle.about;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layouts pageTitle="About Page">
      <NavigationMenu />
      <PageTitle
        pageTitle={data.title}
        background={data.background}
        pageName={data.pageName}
      />
      <About />

      <Footer />
      <ScrollTop />
    </Layouts>
  );
};
export default AboutPage;
