import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeFirstLetterPrimary } from "../../utils/styling";
const NavigationMenu = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const closeModal = () => setModalOpen(false);
  const toggleMenu = () => setModalOpen(!modalOpen);

  const singleMenuItemStyleMobile = {
    fontSize: "38px",
    fontWeight: "500",
    marginTop: "0.3rem",
    marginBottom: "0.3rem",
  };

  return (
    <>
      <header className="header-style2 header-style3 make_me_sticky noselect">
        <div className="menu-inner-area">
          <div className="container">
            <div className="mt-1 mb-1 row align-items-center position-relative">
              <div className="ml-2 col-md-3 col-5 col-sm-4">
                <div className="logo-box">
                  <Link to="/">
                    <img src="/assets/img/logo2.png" alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col d-flex justify-content-end mr-4">
                <div className="menu-wrapper position-static">
                  <div className="header-menu position-static">
                    {!modalOpen && (
                      <div id="menu-button" onClick={toggleMenu}>
                        <i className="flaticon-menu"></i>
                      </div>
                    )}
                    {isMobile ? (
                      modalOpen && (
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(255, 255, 255)",
                            zIndex: 1050,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                          }}
                          onClick={closeModal}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "20px",
                              right: "20px",
                              fontSize: "64px",
                              cursor: "pointer",
                              color: "var(--primary-color)",
                            }}
                            onClick={closeModal}
                          >
                            &times;
                          </div>
                          <ul
                            style={{
                              listStyle: "none",
                              padding: "6rem",
                              display: "block",
                            }}
                          >
                            <li>
                              <Link
                                to="/"
                                onClick={closeModal}
                                style={singleMenuItemStyleMobile}
                              >
                                {makeFirstLetterPrimary("Start")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/services"
                                onClick={closeModal}
                                style={singleMenuItemStyleMobile}
                              >
                                {"Projekty"}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/about"
                                onClick={closeModal}
                                style={singleMenuItemStyleMobile}
                              >
                                {"O nas"}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/contact"
                                onClick={closeModal}
                                style={singleMenuItemStyleMobile}
                              >
                                {"Kontakt"}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      )
                    ) : (
                      <ul style={{ display: "block" }}>
                        <li>
                          <Link to="/">Start</Link>
                        </li>
                        <li>
                          <Link to="/services">Projekty</Link>
                        </li>
                        <li>
                          <Link to="/about">O nas</Link>
                        </li>
                        <li>
                          <Link to="/contact">Kontakt</Link>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavigationMenu;
